<template src="./InputCurrency.html"></template>

<script>
export default {
  name: "InputCurrency",
  data: function () {
    return {
      isInputActive: false,
      event: "",
    };
  },
  props: ["value"],
  computed: {
    // Convierte un texto en formato de moneda
    displayValue: {
      get: function () {
        if (this.value) {
          if (this.isInputActive) {
            return this.value.toString();
          } else {
            return (
              "$ " +
              this.value
                .toFixed(0)
                .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")
            );
          }
        } else {
          return null;
        }
      },
      // Escribe el texto formateado sin puntos
      set: function (modifiedValue) {
        let newValue = parseFloat(modifiedValue.replace(/[^\d\.]/g, ""));
        if (isNaN(newValue)) {
          newValue = 0;
        }
        this.$emit("input", newValue);
      },
    },
  },
  methods: {
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
  },
};
</script>
