<template src="./EditOffer.html"></template>

<script>
import { required } from "vuelidate/lib/validators";
import jobsService from "@/services/Jobs";
import { mapGetters } from "vuex";
import InputCurrency from "../../components/InputCurrency/InputCurrency";
import categoryService from "@/services/Category";

export default {
  name: "EditOffer",
  props: {
    data: {
      type: Object
    }
  },
  components: {
    InputCurrency
  },
  data() {
    return {
      token: "",
      password: "",
      password_verify: "",
      recoverState: "waiting",
      name: this.data.name,
      place: this.data.location,
      salary: this.data.salary,
      typeOfContract: this.data.jobType,
      maxDate: new Date(this.data.deadline),
      maxApplicants: this.data.maxNumberOfParticipants,
      companyDescription: this.data.companyDescription,
      jobDescription: this.data.description,
      responsibilities: this.data.responsibilities,
      academicRequirements: this.data.requirements,
      category: this.data.category.id,
      subcategory: this.data.subcategory.id,
      background: this.data.experience,
      technologicalTools: this.data.technologicalTools,
      optionsMaxApplicants: [20, 40, 60, 80, 100, 120, 140, 150],
      radio: 150,
      step: -1,
      categoryOptions: [],
      subcategoryOptions: null
    };
  },
  validations: {
    name: { required },
    place: { required },
    salary: {},
    typeOfContract: {},
    maxDate: {},
    maxApplicants: {},
    companyDescription: {},
    jobDescription: { required },
    responsibilities: { required },
    academicRequirements: { required },
    category: { required },
    subcategory: { required },
    background: { required },
    technologicalTools: { required }
  },
  mounted() {
    this.getCategories();
  },
  methods: {
    async updateOffer() {
      try {
        const data = {
          name: this.name,
          location: this.place,
          salary: this.salary,
          jobType: this.typeOfContract,
          description: this.jobDescription,
          responsibilities: this.responsibilities,
          experience: this.background,
          deadline: this.maxDate,
          maxNumberOfParticipants: this.maxApplicants,
          companyDescription: this.companyDescription,
          requirements: this.academicRequirements,
          categoryId: this.category,
          subcategoryId: this.subcategory,
          technologicalTools: this.technologicalTools
        };
        Object.keys(data).forEach(function(key) {
          if (data[key] === null || data[key] === "") delete data[key];
        });
        const response = await jobsService.updateJob(data, this.data.id);
        await this.$router.push({
          name: "ViewOffer",
          params: { id: response.id }
        });
        this.$buefy.toast.open({
          duration: 5000,
          message: "Oferta actualizada correctamente",
          position: "is-bottom",
          type: "is-success"
        });
      } catch (e) {
        this.$buefy.toast.open({
          duration: 5000,
          message: e.response.data.message,
          position: "is-bottom",
          type: "is-danger"
        });
        console.error({ error: "updateOffer", e });
      }
    },
    back() {
      this.$router.go(-1);
    },
    async getCategories() {
      try {
        this.categoryOptions = await categoryService.getCategoriesWhitSubcategory();
        const filterCategory = this.categoryOptions.find(element => {
          if (element.id === this.category) {
            return element;
          }
        });
        this.subcategoryOptions = filterCategory.subcategory;
      } catch (e) {
        console.error("error", e);
      }
    }
  },
  computed: {
    ...mapGetters({
      getJobApplicationPhases: "getJobApplicationPhases"
    })
  },
  updated() {
    if (this.category) {
      const filterCategory = this.categoryOptions.find(element => {
        if (element.id === this.category) {
          return element;
        }
      });
      this.subcategoryOptions = filterCategory.subcategory;
    }
  }
};
</script>

<style scoped lang="scss" src="./EditOffer.scss"></style>
